/**
 * Parses sort from bootstrap tables to array of field:direction
 * @param {object} sort
 * @param {string} sort.field
 * @param {boolean} sort.orderIsDesc
 */
export const parseSort = (sort) => {
  if (!sort.field) {
    return '';
  }

  return `${sort.field}:${sort.orderIsDesc ? 'desc' : 'asc'}`;
};

export const arrayToQuerystring = (array) => {
  if (!array || !array.length) {
    return '';
  }

  return array.join(',');
};
