<template lang="pug">
.widget
  loading(:active.sync="isBusy", :is-full-page="true")
  .widget-header
    h1.title Support Tickets
    i.la.la-2x.la-filter.d-flex.align-items-center.mobile(@click="areFiltersOpened = !areFiltersOpened")
  .widget-body
    filters(@submit="loadData()", :default-filters="defaultFilters", :current-filters="filters", :page-name="'supportTicketsList'", :is-opened="areFiltersOpened", @close-filters="areFiltersOpened = false")
      .form-row-main
        .form-group
          label Search
          input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
        .form-group
          label Status
          select.form-control(v-model="filters.generalStatus")
            option(v-for="o in generalStatusOptions", :value="o.v") {{ o.t }}
        select-advertiser(v-model="filters.advertiserId")
        select-offer(v-model="filters.offerId")
        select-publisher(v-model="filters.publisherId")
        select-placement(v-model="filters.placementId")
        .form-group.no-label
          b-button(type="submit", variant="primary") Go

      .form-row-end
        .form-group
          label &nbsp;
          router-link.btn.btn-success(:to="{ name: 'support-ticket-add' }")
            i.la.la-plus
            | New ticket

    .ov-table-wrapper
      b-table.table(
        hover
        :items="records"
        :fields="tableFields"
        :sort-by.sync="sort.field"
        :sort-desc.sync="sort.orderIsDesc"
        no-local-sorting
        sort-icon-left
        :tbody-tr-class="rowClass"
      )
        template(#cell(createdAt)="{ item: { createdAt } }")
          span {{ createdAt | formatDateEU }}
        template(#cell(dueDate)="{ item: { dueDate } }")
          span {{ dueDate | formatDateEU }}
        template(#cell(generalStatus)="{ item: { generalStatus } }")
          b-badge(:variant="getGeneralStatusBadgeVariant(generalStatus)") {{ getBadgeText(generalStatus) }}
        template(#cell(progressStatus)="{ item: { progressStatus } }")
          b-badge(:variant="getProgressStatusBadgeVariant(progressStatus)") {{ getBadgeText(progressStatus) }}
        template(#cell(publisher)="{ item: { publisher } }")
          span(v-if="publisher") [{{ publisher.id }}] {{ publisher.name }}
        template(#cell(advertiser)="{ item: { advertiser } }")
          span(v-if="advertiser") [{{ advertiser.id }}] {{ advertiser.name }}
        template(#cell(offer)="{ item: { offer } }")
          span(v-if="offer") [{{ offer.id }}] {{ offer.name }}
        template(#cell(placement)="{ item: { placement } }")
          span(v-if="placement") [{{ placement.id }}] {{ placement.name }}
        template(#cell(actions)="{ item: { id } }")
          router-link.btn.btn-sm.btn-secondary(:to="{ name:'support-ticket-edit', params: { id } }")
            i.la.la-pencil
      paginate(:paginator="paginate", @update-page-size="loadData")
</template>

<script>
import Vue from 'vue';
import { parseSort, arrayToQuerystring } from '../../lib/querystring';

export default {
  name: 'SupportList',
  async beforeRouteEnter(to, from, next) {
    try {
      const resp = await Vue.ovData.support.getList({
        filters: { generalStatus: Vue.ovData.support.GeneralStatus.New },
        limit: 50
      });
      next((vm) => {
        vm.records = resp.list;
        vm.paginate.total = resp.count;
        vm.paginate.numPages = Math.max(Math.ceil(vm.paginate.total / vm.paginate.limit), 1);
        return vm;
      });
    } catch (error) {
      Vue.ovNotify.error(error);
      next();
    }
  },
  data() {
    const defaultFilters = {
      keyword: '',
      generalStatus: Vue.ovData.support.GeneralStatus.New,
      advertiserId: [],
      publisherId: [],
      offerId: [],
      placementId: [],
    };
    return {
      areFiltersOpened: false,

      isBusy: false,

      records: [],
      count: 0,

      tableFields: [
        { key: 'title', label: 'Title' },
        { key: 'generalStatus', label: 'Status', class: 'text-capitalize' },
        { key: 'progressStatus', label: 'Progress', class: 'text-capitalize' },

        { key: 'publisher', label: 'Publisher' },
        { key: 'advertiser', label: 'Advertiser' },
        { key: 'offer', label: 'Offer' },
        { key: 'placement', label: 'Placement' },

        { key: 'type', label: 'Type', class: 'text-capitalize' },
        { key: 'priority', label: 'Priority', class: 'text-capitalize', sortable: true },
        { key: 'user.name', label: 'Created By' },
        { key: 'createdAt', label: 'Creation Date' },
        { key: 'dueDate', label: 'Due Date' },
        { key: 'id', label: 'ID' },
        { key: 'actions', label: '', class: 'actions' },
      ],

      paginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadData();
        },
      },

      generalStatusOptions: [
        { t: 'All', v: null },
        { t: 'New', v: Vue.ovData.support.GeneralStatus.New },
        { t: 'Awaiting Reply', v: Vue.ovData.support.GeneralStatus.PendingClient },
        { t: 'Open', v: Vue.ovData.support.GeneralStatus.Open },
        { t: 'Closed', v: Vue.ovData.support.GeneralStatus.Closed },
        { t: 'Cancelled', v: Vue.ovData.support.GeneralStatus.Cancelled },
      ],
      filtersExpanded: false,
      defaultFilters,
      filters: { ...defaultFilters },
      sort: {
        field: '',
        orderIsDesc: false,
      },

      GeneralStatus: Vue.ovData.support.GeneralStatus,
      ProgressStatus: Vue.ovData.support.ProgressStatus,
    };
  },
  watch: {
    sort: {
      async handler() {
        await this.loadData();
      },
      deep: true,
    },
  },
  methods: {
    async loadData() {
      try {
        this.isBusy = true;
        const sort = parseSort(this.sort);
        const advertiserId = arrayToQuerystring(this.filters.advertiserId.map((a) => a.v));
        const offerId = arrayToQuerystring(this.filters.offerId.map((a) => a.id));
        const placementId = arrayToQuerystring(this.filters.placementId.map((a) => a.id));
        const publisherId = arrayToQuerystring(this.filters.publisherId.map((a) => a.v));
        const filters = { ...this.filters };
        if (!filters.generalStatus) {
          delete filters.generalStatus;
        }
        const resp = await Vue.ovData.support.getList({
          page: this.paginate.page,
          limit: this.paginate.limit,
          filters: { ...filters, advertiserId, offerId, placementId, publisherId },
          sort,
        });
        this.records = resp.list;
        this.paginate.total = resp.count;
        this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
      } catch (error) {
        Vue.ovNotify.error(error);
      }
      this.isBusy = false;
    },
    toggleFilters() {
      this.filtersExpanded = !this.filtersExpanded;
    },

    /** @param {string} status */
    getBadgeText(status) {
      if (status === this.GeneralStatus.PendingClient) {
        return 'Awaiting Reply';
      }
      return status.replace(/_/g, ' ');
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return;
      if (item.lastUpdateFrom === 'support') return 'table-success';
    },

    getProgressStatusBadgeVariant(status) {
      switch (status) {
        case this.ProgressStatus.New:
          return 'secondary';
        case this.ProgressStatus.InProgress:
          return 'primary';
        case this.ProgressStatus.ReadyForDeployment:
          return 'warning';
        case this.ProgressStatus.Discussion:
          return 'dark';
        case this.ProgressStatus.Qa:
          return 'info';
        case this.ProgressStatus.Deployed:
          return 'success';
        default:
          return 'primary';
      }
    },

    getGeneralStatusBadgeVariant(status) {
      switch (status) {
        case this.GeneralStatus.New:
          return 'secondary';
        case this.GeneralStatus.PendingClient:
          return 'dark';
        case this.GeneralStatus.Open:
          return 'primary';
        case this.GeneralStatus.Closed:
          return 'success';
        case this.GeneralStatus.Cancelled:
          return 'danger';
        default:
          return 'primary';
      }
    }
  }
};
</script>
